<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·我的共享客户/同事共享给我的的页面
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-12-14
备注说明：如需修改请联系开发人员
-->

<template>
	<div id="crm-content" class="crm-content cus-share">
		<!-- 顶部导航栏位置 -->
		<div class="crm-content-title" v-if="!isTeam">
			<i class="icon iconfont icon-zhuye index-icon" @click="returnCrm"></i>
			<span class="crm-content-site" v-for="title in myTitle">您的位置：{{title.name}} <i class="refresh"></i></span>
		</div>
		<div class="crm-content-title" v-if="isTeam">
			<router-link to="/team/customer">
				<i class="icon iconfont icon-zhuye index-icon" style="float: left;color: #333;"></i>
			</router-link>
			<span class="crm-content-site" v-for="title in myTitle">您的位置：{{title.name}} <i class="refresh"></i></span>
		</div>
		<!-- 操作 -->
		<div class="crm-origin-operator" v-show="share_operator">
			<div class="operator-box">
				<div class="operator-btn operate-title"><span>操作:</span></div>
				<div class="operator-btn" @click="cancelShare" v-if="hasCancelShare"><span class="operate-btn">取消共享</span></div>
				<div class="operator-btn" @click="exportShare" v-if="showSystemAdmin"><span class="operate-btn">导出</span></div>
			</div>
		</div>
		<!-- 表格 -->
		<div class="crm-table w100">
			<div class="w100">
				<table width="100%" cellpadding="0" cellspacing="0">
					<thead>
						<tr>
							<th height="30" class="th" style="width: 20px; padding: 10px 0px;"></th>
							<th height="30" class="th" width="50">
								<el-checkbox @change="selectAll($event)" class="selectButton"></el-checkbox>
							</th>
							<th height="30" class="th">
								<span>企业信息</span>
							</th>
							<th height="30" class="th" width="150">
								<span>录入时间</span>
							</th>
							<th height="30" class="th" width="150">
								<span>共享时间</span>
							</th>
							<th height="30" class="th" width="200">
								<span>最后联系时间</span>
							</th>
							<th width="150" height="30" class="th" v-if="hasCancelShare">
								<span>共享给</span>
							</th>
							<th width="150" height="30" class="th" v-if="!hasCancelShare">
								<select class="dropMenu" v-model="userid">
									<option value="0">所有</option>
									<option v-for="user in employeesList" :value="user.user_id" :title="user.user_name">{{user.user_name1}}</option>
								</select>
							</th>
							<th width="150" height="30" class="th" v-if="isTeam">
								<select class="dropMenu" v-model="userids" @change="findByUser" id="findByUser">
									<option v-for="user in employeesList" :value="user.user_id" :title="user.user_name">{{user.user_name1}}</option>
								</select>
							</th>
							 <th width="18" class="th" v-show='myShareList.length>9' style="padding: 8px 0;"></th>
							 <th width="18" class="th" v-show='otherShareList.length>9' style="padding: 8px 0;"></th>
						</tr>
				</thead>
				</table>
			</div>
			<div class="w100 newTableStyle-share">
				<table width="100%" cellpadding="0" cellspacing="0" v-loading="autographLoading" element-loading-text="拼命加载中">
				<tbody>
					<tr v-if="hasCancelShare" v-for="(td,index) in myShareList" class="_tr">
						<td class="td" height="50" style="width: 20px; padding: 10px 0px;"></td>
						<td class="td" width="50">
							<el-checkbox v-model="selectArr" :label="td.customer_id" :key="index"></el-checkbox>
						</td>
						<td class="td">
							<div>
								<img :src="td.img_url" alt="客户头像" class="user-bg" />
								<span class="user-info">
                <p class="crm-infoTitle" @click="openCustomerInfo(td.customer_id,td.user_id)">{{td.customer_name}}</p>
                <br>
                <p class="crm-infoEmail" @click="clickMessage(td.link_email,td.customer_id)" title="点击快捷写信">{{td.link_email}}</p>
              </span>
							</div>
						</td>
						<td class="td" width="150">
							<span :title="td.enrol_time">{{td.enrol_date}}</span>
						</td>
						<td class="td" width="150">
							<span>{{td.share_date}}</span>
						</td>
						<td class="td" width="200">
							<span :title="td.contact_time">{{td.contact_date}}</span>
						</td>
						<td class="td" width="150">
							<span class="share-colleague" @click="lookMore(td.shareids)">查看</span>
						</td>
						<td class="td" width="150" v-show="isTeam">
							<span>{{td.user_name}}</span>
						</td>
					</tr>
					
					<tr v-if="!hasCancelShare" v-for="(td,index) in otherShareList" class="_tr">
						<td class="td" height="50" style="width: 20px; padding: 10px 0px;"></td>
						<td class="td" width="50">
							<div>
								<el-checkbox v-model="selectArr" :label="td.customer_id" :key="index"></el-checkbox>
							</div>
						</td>
						<td class="td">
							<div>
								<img :src="td.img_url" alt="客户头像" class="user-bg" />
								<span class="user-info">
                <p class="crm-infoTitle" @click="openCustomerInfo(td.customer_id,td.user_id)">{{td.customer_name}}</p>
                <br>
                <p class="crm-infoEmail" @click="clickMessage(td.link_email,td.customer_id)" title="点击快捷写信">{{td.link_email}}</p>
              </span>
							</div>
						</td>
						<td class="td" width="150">
							<span :title="td.enrol_time">{{td.enrol_date}}</span>
						</td>
						<td class="td" width="150">
							<span>{{td.share_date}}</span>
						</td>
						<td class="td" width="200">
							<span :title="td.contact_time">{{td.contact_date}}</span>
						</td>
						<td class="td" width="150">
							<span>{{td.sharename}}</span>
						</td>
					</tr>
				</tbody>
			</table>
			<!-- 我的共享客户暂无数据 -->
			<div class="myNoData" v-if="hasCancelShare&&myShareList.length == 0">
				<p><i class="icon iconfont icon-zanwushuju"></i></p>
				<span>暂无数据</span>
			</div>
			<!-- 同事共享给我的暂无数据 -->
			<div class="myNoData" v-if="!hasCancelShare&&otherShareList.length == 0">
				<p><i class="icon iconfont icon-zanwushuju"></i></p>
				<span>暂无数据</span>
			</div>
			</div>
		</div>
		<!-- 我的共享客户暂无数据 -->
		<div class="newPagingStyle" v-if="hasCancelShare&&myShareList.length>0">
			<el-pagination class="crmCCenter" :current-page.sync="my_currpage" 
				:page-sizes="[10,20,30,40,50]" :page-size="my_pagesize" :total="my_tolRecord"
				layout="total,sizes, prev, pager, next" :page-count='my_tolpage' 
				@size-change='sizeChange1' @current-change='getMyShareList()'>
			</el-pagination>
		</div>
		<!-- 同事共享给我的暂无数据 -->
		<div class="newPagingStyle" v-if="!hasCancelShare&&otherShareList.length>0">
			<el-pagination class="crmCCenter" :current-page.sync="other_currpage" 
				:page-sizes="[10,20,30,40,50]" :page-size="other_pagesize" :total="other_tolRecord"
				layout="total,sizes, prev, pager, next" :page-count='other_tolpage' 
				@size-change='sizeChange2' @current-change='getShareMeList()'>
			</el-pagination>
		</div>
		<!--客户详细信息-->
		<moreInfoEdit v-if="showN" @closeInfo="showN = false" @all_changeState="all_changeState" :isOnlyRead="isTeam || !hasCancelShare" 
			:customer_id="customer_id" :userId="de_userid" :has-editbtn="hasEditbtn" @refreshCusList="loadCustomerList"></moreInfoEdit>
		<!--快捷写信-->
		<writeMessage @closeMessage="showMessage=false" @writeMessage="writeMessages" v-if="showMessage" :my-email="myEmail" :cusorlink="1" :id="customer_id" :cusId="customer_id"></writeMessage>
		<!-- 共享给弹出框 -->
		<el-dialog title="共享给" :visible.sync="toShowShare" size="tiny" class='acus-popup4 dialogTwo' :close-on-click-modal="false" :modal="false">
			<table cellspacing="0" class="acusOpen-table">
				<thead>
					<tr>
						<td>序号</td>
						<td>姓名</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item,index) in toShareList">
						<td>
							<span>{{index + 1}}</span>
						</td>
						<td>
							<span>{{item}}</span>
						</td>
					</tr>
				</tbody>
			</table>
		</el-dialog>
	</div>
</template>
<script>
	import writeMessage from '../../CommonItem/writeMessage.vue'
	import commonSearch from '../../CommonItem/commonSearch.vue'
	import moreInfoEdit from "../../CommonItem/crmMoreInfo_Editor.vue"
	import { Message } from 'element-ui';
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
	export default({
		name: "crmContent",
		data() {
			return {
				showMessage: false,//快捷写信弹窗
				showN: false,
				userid: 0, //用户的id
				userids: "",//用户id
				autographLoading: false, //加载框
				toShowShare: false,//共享给弹窗
				toShareList: [],//共享给数据集合
				selectArr: [],//复选框
				//我的共享客户
				myShareList: [],//我的共享数据集合
				my_currpage: 1,//当前页
				my_tolpage: 1, //总页数
				my_pagesize: 10, //每页条数
				my_tolRecord:1,//总条数
				//同属共享给我的
				otherShareList: [],//同属共享给我的数据集合
				other_currpage: 1,
				other_tolpage: 1,
				other_pagesize: 10,
				other_tolRecord:1,//总条数
				
				customer_id: 0, //客户id
				de_userid:0,//详情用户id
				myEmail: '',//快捷写信的邮箱
				employeesList: [],//全部员工
				hasEditbtn: false,
				showSystemAdmin:false,//只有系统管理员才能显示(导出客户)
				share_operator:true,//操作整行
			}
		},
		props: [
			'myTitle',
			'isTeam',
			'hasCancelShare',
		],
		components: {
			moreInfoEdit,
			commonSearch,
			writeMessage
		},
		computed: {
			...mapState({
				userinfo: state => state.userinfo,
				userlistall: state => state.userlistall,
				loadCusNumTag: state => state.loadCusNumTag,
				userparamlist: state => state.userparamlist,
			}),
		},
		methods: {
			...mapMutations({
				getUserlistAll: 'getUserlistAll',
			}),
			//全选或取消全选
			selectAll(event) {
				var lef = this;
				// if(!event.currentTarget.checked) {
				if(!event) {
					//反选
					this.selectArr = [];
				} else { //实现全选
					lef.selectArr = [];
					if(lef.hasCancelShare) {
						lef.myShareList.forEach(function(item, i) {
							lef.selectArr.push(item.customer_id);
						});
					} else {
						lef.otherShareList.forEach(function(item, i) {
							lef.selectArr.push(item.customer_id);
						});
					}
				}
			},
			//页数改变执行
			sizeChange1(size) {
				this.my_pagesize = size;
				this.getMyShareList();
			},
			//通过客户所属员工查询
			findByUser(){
				this.my_pagesize = 10;
				this.my_currpage = 1;
				this.getMyShareList();
			},
			//查看共享用户
			lookMore(userids) {
				//console.log(userids)
				var lef = this;
				lef.toShareList = [];
				if(userids.indexOf(',') != -1){
					var user_arr = userids.split(',');
					for(var i = 0; i < user_arr.length; i++) {
						var id = parseInt(user_arr[i]);
						$.each(this.userlistall, function(j, user) {
							if(user.user_id == id) {
								lef.toShareList.push(user.user_name);
								return false;
							}
						});
					}
				}else{
					$.each(this.userlistall, function(j, user) {
						if(user.user_id == userids) {
							lef.toShareList.push(user.user_name);
							return false;
						}
					});
				}
				this.toShowShare = true;
			},
			//加载我的共享客户列表
			getMyShareList() {
				let lef = this;
				lef.selectArr = [];
				
				var param = {
					token: token,
					pagesize: lef.my_pagesize,
					currpage: lef.my_currpage,
				};
				
				if(lef.isTeam) {
					param.userids = lef.userids;
				}
				
				$.ajax({
					type: "post",
					url: backUrl + "cusCC_findMyShareCustomer.action",
					async: true,
					cache: false,
					dataType: "json",
					beforeSend: function() {
						lef.autographLoading = true;
					},
					complete: function() {
						lef.autographLoading = false;
					},
					data: param,
					success: function(data) {
						lef.my_tolpage = data.pageBean.totalPage; //总页数
						lef.my_tolRecord = data.pageBean.totalRecord;//总条数
						lef.myShareList = [];
						$.each(data.cooperatelist, function(index, row) {
							var imgurl = backUrl + "img/male_user.png";
							if(row.customer.img_url !== undefined && row.customer.img_url !== null  && row.customer.img_url != 'null'  &&  row.customer.img_url != 'null' &&  row.customer.img_url !== "") {
								if(row.customer.img_url.indexOf("http://")!=-1 || row.customer.img_url.indexOf("https://")!=-1){
									imgurl = row.customer.img_url;
								}else{
									imgurl = backUrl + row.customer.img_url;
								}
							}
							var enrol_date = row.customer.enrol_time.split(" ")[0];
							var enrol_time = row.customer.enrol_time.split(" ")[1];
							var contact_date = "暂无活跃";
							var contact_time = "";
							if(row.customer.last_contact_date != null && row.customer.last_contact_date != '') {
								contact_date = row.customer.last_contact_date.split(" ")[0];
								contact_time = row.customer.last_contact_date.split(" ")[1];
							}
							var customer_name = row.customer.customer_name;
							if(customer_name.length > 50) {
								customer_name = customer_name.substring(0, 47) + '...';
							}
							
							//所属用户
							var user_name = "未知用户";
							for(var s in lef.userlistall) {
								if(row.auditor == lef.userlistall[s].user_id) {
									user_name = lef.userlistall[s].user_name;
									break;
								}
							}
							lef.myShareList.push({
								"customer_id": row.customer_id,
								"img_url": imgurl,
								"customer_name": customer_name,
								"link_email": row.customer.email,
								"enrol_date": enrol_date,
								"enrol_time": enrol_time,
								"share_date": row.create_date,
								"contact_date": contact_date,
								"contact_time": contact_time,
								"user_name": user_name,
								"shareids": row.user_id,
								"user_id":row.customer.user_id,
							});
						});
					}
				});
			},
			//页数改变执行
			sizeChange2(size) {
				this.other_pagesize = size;
				this.getShareMeList();
			},
			//加载共享给我的客户列表
			getShareMeList() {
				let lef = this;
				lef.selectArr = [];
				$.ajax({
					type: "post",
					url: backUrl + "cusCC_findShareCustomer.action",
					async: true,
					cache: false,
					dataType: "json",
					beforeSend: function() {
						lef.autographLoading = true;
					},
					complete: function() {
						lef.autographLoading = false;
					},
					data: {
						token: token,
						pagesize: lef.other_pagesize,
						currpage: lef.other_currpage,
						upid: lef.userid
					},
					success: function(data) {
						lef.other_tolpage = data.pageBean.totalPage; //总页数
						lef.other_tolRecord = data.pageBean.totalRecord;//总条数
						lef.otherShareList = [];
						$.each(data.cooperatelist, function(index, row) {
							var imgurl = backUrl + "img/male_user.png";
							if(row.customer.img_url !== undefined && row.customer.img_url !== null && row.customer.img_url != 'null'  && row.customer.img_url !== "") {
								if(row.customer.img_url.indexOf("http://")!=-1 || row.customer.img_url.indexOf("https://")!=-1){
									imgurl = row.customer.img_url;
								}else{
									imgurl = backUrl + row.customer.img_url;
								}	
							}
							var enrol_date = row.customer.enrol_time.split(" ")[0];
							var enrol_time = row.customer.enrol_time.split(" ")[1];
							var contact_date = "暂无活跃";
							var contact_time = "";
							if(row.customer.last_contact_date != null && row.customer.last_contact_date != '') {
								contact_date = row.customer.last_contact_date.split(" ")[0];
								contact_time = row.customer.last_contact_date.split(" ")[1];
							}
							var customer_name = row.customer.customer_name;
							if(customer_name.length > 50) {
								customer_name = customer_name.substring(0, 47) + '...';
							}
							var sharename = "未知";
							if(lef.userlistall == '') {
								lef.getUserlistAll();
							}
							$.each(lef.userlistall, function(j, user) {
								if(user.user_id == row.auditor) {
									sharename = user.user_name;
									return false;
								}
							});
							lef.otherShareList.push({
								"customer_id": row.customer_id,
								"user_id": row.user_id,
								"img_url": imgurl,
								"customer_name": customer_name,
								"link_email": row.customer.email,
								"enrol_date": enrol_date,
								"enrol_time": enrol_time,
								"share_date": row.create_date,
								"contact_date": contact_date,
								"contact_time": contact_time,
								"sharename": sharename,
								"user_id":row.customer.user_id,
							});
						});
					}
				});
			},
			//取消共享
			cancelShare() {
				if(!this.commonJs.getUserPower(4,3)){
				return
			}
				if(this.selectArr.length == 0) {
					this.$message({
						showClose: true,
						message: '请选择客户列表！',
						type: 'warning',
						duration: 2000
					});
					return;
				}
				this.$confirm('确定取消共享?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					closeOnClickModal: false,
					cancelButtonClass: 'MBcancelBtn'
				}).then(() => {
					var cud_ids = ''; //客户id  
					$.each(this.selectArr, function(i, row) {
						cud_ids += row + ',';
					});
					cud_ids = cud_ids.substring(0, cud_ids.length - 1);
					var lef = this;
					$.ajax({
						"type": "POST",
						"async": false,
						"cache": false,
						"url": backUrl + "cusCC_updateShareCanel.action",
						"data": {
							token: token,
							customerid: cud_ids
						},
						success: function(msg) {
							if(msg == 1) {
								lef.$message({
									showClose: true,
									message: '取消成功！',
									type: 'success',
									duration: 2000
								});
								lef.getMyShareList();
								//重新加载我的共享客户数量
								lef.$store.state.loadCusNumTag = 4;
							} else {
								lef.$message({
									showClose: true,
									message: '取消失败！',
									type: 'warning',
									duration: 2000
								});
							}
						},
						error: function() {
							lef.$message({
								showClose: true,
								message: '取消失败！',
								type: 'warning',
								duration: 2000
							});
						}
					});
				}).catch(() => {});
			},
			//导出
			exportShare() {
				if(this.hasCancelShare) { //导出我的共享客户
					if(this.selectArr.length == 0) {
						var msg = '是否导出全部我共享给别人的客户信息?';
						if(this.isTeam) {
							msg = '是否导出' + $('#findByUser option:selected').attr("title") + '的共享客户信息?';
						}
						this.$confirm(msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
							closeOnClickModal: false,
							cancelButtonClass: 'MBcancelBtn'
						}).then(() => {
							var urlHref = backUrl + "export_exportCustomerByType.action?token=" + token + "&export_type=3&excel_name=我共享给别人的客户";
							if(this.isTeam) {
								urlHref += "&userid=" + this.userids;
							}
							//$("#downloadid").attr("src", urlHref);
							window.open(urlHref);
						}).catch(() => {});
					} else { //选中
						var cud_ids = ''; //客户id  
						$.each(this.selectArr, function(i, row) {
							cud_ids += row + ',';
						});
						cud_ids = cud_ids.substring(0, cud_ids.length - 1);
						var urlHref = backUrl + "export_exportCustomerByType.action?token=" + token + "&export_type=1&customerids=" + cud_ids + "&excel_name=我共享给别人的客户";
						//$("#downloadid").attr("src", urlHref);
						window.open(urlHref);
					}
				} else { //导出别人共享给我的客户
					if(this.selectArr.length == 0) {
						this.$confirm('是否导出全部别人共享给我的客户信息?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
							closeOnClickModal: false,
							cancelButtonClass: 'MBcancelBtn'
						}).then(() => {
							var urlHref = backUrl + "export_exportCustomerByType.action?token=" + token + "&export_type=4&excel_name=别人共享给我的客户";
							//$("#downloadid").attr("src", urlHref);
							window.open(urlHref);
						}).catch(() => {});
					} else { //选中
						var cud_ids = ''; //客户id  
						$.each(this.selectArr, function(i, row) {
							cud_ids += row + ',';
						});
						cud_ids = cud_ids.substring(0, cud_ids.length - 1);
						var urlHref = backUrl + "export_exportCustomerByType.action?token=" + token + "&export_type=1&customerids=" + cud_ids + "&excel_name=别人共享给我的客户";
						//$("#downloadid").attr("src", urlHref);
						window.open(urlHref);
					}
				}
			},
			//查看客户详细信息
			openCustomerInfo(cus_id,uid) {
				this.customer_id = cus_id;
				this.de_userid = uid;
				this.hasEditbtn = false;
				this.showN = true;
			},
			all_changeState() {
				this.hasEditbtn = true;
			},
			//客户详情回调
			loadCustomerList() {
				if(this.hasCancelShare) { //加载我的共享客户列表
					this.getMyShareList();
				} else { //加载别人共享给我的客户列表
					this.getShareMeList();
				}
			},
			returnCrm() {
				this.$emit('returnCrm');
			},
			...mapActions([
				'writeLetter'
			]),
			//快捷写信
			clickMessage(email, cus_id) {
				if(this.userparamlist.param39 == undefined || this.userparamlist.param39 == 0) {
					this.$message({
						showClose: true,
						message: '您还未开通邮件模块！',
						type: 'warning',
						duration: 2000
					});
					return;
				}
				this.customer_id = cus_id;
				this.myEmail = email;
				this.showMessage = true;
			},
			//跳转到完整写信
			writeMessages(emails) {
				this.$router.push({
					path: '/mail'
				});
				this.$store.state.mail.wlPagetype = "";
				this.$store.state.mail.wlMailid = 0;
				this.writeLetter({
					name: '写信',
					addressee: emails
				});
				this.showMessage = false;
			},
		},
		mounted() {
			//加载全部员工
			if(this.userlistall == '') {
				this.getUserlistAll();
			}
			
			var self = this;
			
			if(this.isTeam) {
				$.each(this.userlistall, function(i, row) {
					self.userids += "," + row.user_id;
				})
				
				if(self.userids.length > 0) {
					self.userids = self.userids.substring(1);
				}
				
				self.employeesList.push({
					user_name: "全部员工",
					user_id: self.userids,
					user_name1: "全部员工",
				})
			}
			
			$.each(this.userlistall, function(i, row) {
				
				var user_name1 = row.user_name;
				if(user_name1.length > 7) {
					user_name1 = user_name1.substring(0, 7) + "...";
				}
				self.employeesList.push({
					user_name: row.user_name,
					user_id: row.user_id,
					user_name1: user_name1,
				})
			})
			
			
			if(this.hasCancelShare) { //加载我的共享客户列表
				this.getMyShareList();
			} else { //加载别人共享给我的客户列表
				this.getShareMeList();
			}
			
			//导出
			if(this.userinfo.user_role == 1){
				this.showSystemAdmin = true;
			}else{
				this.showSystemAdmin = false;
			}
			if(this.hasCancelShare){
				this.share_operator = true;
				$('.newTableStyle-share').css('top','123px');
			}else{
				if(this.showSystemAdmin){
					this.share_operator = true;
					$('.newTableStyle-share').css('top','123px');
				}else{
					this.share_operator = false;
					$('.newTableStyle-share').css('top','89px');
				}
			}
		},
		watch: {
			userid() {
				this.getShareMeList();
			},
		},
	})
</script>
<style src="../../../../css/crm/allClient.css"></style>
<style>
	.acusOpen-table {
		width: 100%;
	}
	
	.acusOpen-table {
		border-top: 1px solid #bfcbd9;
		border-left: 1px solid #bfcbd9;
		text-align: center;
		line-height: 30px;
	}
	
	.acusOpen-table thead {
		font-weight: bold;
		background: #eef1f6;
	}
	
	.acusOpen-table tr>td {
		width: 30%;
		border-right: 1px solid #bfcbd9;
		border-bottom: 1px solid #bfcbd9;
	}
	
	.acusOpen-table tbody tr:hover {
		background: #eef1f6;
	}
</style>
<style>
	.cus-share .newTableStyle-share {
		/* position: absolute;
		top: 89px;
		bottom: 50px; */
		overflow: auto;
	}
	
	.cus-share .crm-table .dropMenu {
		width: 120px;
		border: 1px solid #bfcbd9;
		border-radius: 2px;
		height: 25px;
		color: #999;
	}
</style>