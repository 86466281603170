<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·同事共享给我的页面
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
	<!-- 引入同事共享给我的页面vue -->
  <share-component
    :my-title="myTitle"
    :has-cancel-share="false"
    @returnCrm="returnCrm"
    :has-editbtn="true">
  </share-component>
</template>
<script>
import shareComponent from './commonShare.vue'
export default({
  data(){
    return{
      myTitle:[
        {name:'同事共享给我的'}
      ],
    }
  },
  components:{
    shareComponent
  },
  methods:{
    returnCrm(){
      this.$emit('returnCrm');
    }
  }
})
</script>
